import { SYNTAX_CLASSNAMES } from '../../colors';
import type { CustomElement, CustomText, SlateTextDecoration } from '../../slate';
import { Editor, Text } from '../../slate/helpers';
import {
    createCallNodeDraftState,
    updateCallNodeDraftStateParams,
    type CallNodeDraftState,
} from './callNodeDraftState';
import {
    CODE_EDITOR_RESULT_CHANGE_EVENT,
    CodeEditorResult,
    type CodeEditorResultChangeEvent,
    type CodeEditorService,
    type EditorDecorationInputHelper,
    type EditorServiceParseOptions,
} from './interface';
import { renderArgumentToString } from './renderArgumentToString';
import { mapEditorElementsToAstNodes, SLATE_ELEMENT_TO_AST_NODE } from './slateElementToAstNode';
import { updateIndicatorNode } from './updateIndicatorNode';
import { iterateAst, iterateAstUpward, render, type CallNode, type KnownAstNode } from '@thinkalpha/language-services';
import debounce from 'lodash/debounce';
import { from } from 'rxjs';
import { type Range, Transforms, type Path } from 'slate';
import { ReactEditor } from 'slate-react';
import type { ConcreteIndicatorViewModel } from 'src/contracts/dictionary-view-model';
import { injectable, inject, ReactiveInjectable, reacts } from 'src/features/ioc';
import type { ReactBindings } from 'src/ioc/types';
import { IndicatorImportModel } from 'src/models/IndicatorImport';

const EMPTY_CODE_EDITOR_RESULT: CodeEditorResult = {
    code: '',
    result: null,
    isValid: false,
    errors: [],
};

@injectable()
export class CodeEditorServiceImpl extends ReactiveInjectable implements CodeEditorService {
    #updateParseOptionsPromise: Promise<void> | null = null;

    #editorResult: CodeEditorResult = EMPTY_CODE_EDITOR_RESULT;

    #editor!: ReactEditor;

    #decorations: Map<CustomText, SlateTextDecoration> = new Map();

    importsManager!: ReactBindings['ImportsManagerModel'];

    #autoUpdateImports!: (node: KnownAstNode) => void;

    /**
     * The user's selection in the code editor
     */
    #selection: Range | null = null;

    /**
     * Is the user focus in the code editing domain
     */
    #isFocused: boolean = false;

    /**
     * The known AST node under the user's selection
     */
    #knownAstNodeUnderSelection: KnownAstNode | null = null;

    /**
     * The current draft state of a call node being modified
     */
    #callNodeDraftState: CallNodeDraftState | null = null;

    /**
     * This will be present when a code update is in progress which may be cancelled
     */
    #codeUpdateAbortController: AbortController | null = null;

    async acceptSuggestion(indicator: ConcreteIndicatorViewModel) {
        const locationOfInsert = this.#selection;

        if (!locationOfInsert) return;

        const astNode = this.#knownAstNodeUnderSelection;
        if (astNode?.type === 'call') {
            const originalCallNode = astNode;

            // TODO: Alias this if necessary
            const replacementCallNode: CallNode = { ...astNode, source: indicator.symbol };
            const replacementCallString = render(replacementCallNode);

            updateIndicatorNode(originalCallNode, replacementCallString, this.#editor);
        } else {
            const textToInsert = `${indicator.symbol}()`;
            Transforms.insertText(this.#editor, textToInsert);
        }

        // For some reason this is needed to make sure the editor is focused
        await new Promise((resolve) => setTimeout(resolve, 0));
        ReactEditor.focus(this.#editor);

        const point = { path: locationOfInsert.anchor.path, offset: 0 };
        Transforms.setSelection(this.#editor, { anchor: point, focus: point });
        // TODO: Distance differently by alias
        Transforms.move(this.#editor, { distance: indicator.symbol.length + 1, unit: 'character' });

        {
            if (this.importsManager.imports.some((i) => i.alias === indicator.symbol)) {
                // Already have an import for this indicator, don't re-import or overwrite
                return;
            }
            this.importsManager.addImports(
                IndicatorImportModel.fromIndicatorImportViewModel({ alias: indicator.symbol, ...indicator }),
            );
        }
    }

    cancelIndicatorNodeDraft() {
        if (!this.#callNodeDraftState) {
            this.setEditorFocus(false);
            return;
        }

        {
            this.#editor.children = this.#callNodeDraftState.slateEditorChildrenSnapshot;
            Editor.normalize(this.#editor, {
                force: true,
            });
        }

        this.#callNodeDraftState = null;

        this.setEditorFocus(false);
    }

    commitIndicatorNodeDraft(blurEditor = true) {
        if (!this.#callNodeDraftState) {
            if (blurEditor) {
                this.setEditorFocus(false);
            }
            return;
        }

        const { originalCallNode, replacementCallString, slateEditorChildrenSnapshot } = this.#callNodeDraftState;

        this.#editor.children = slateEditorChildrenSnapshot;
        updateIndicatorNode(originalCallNode, replacementCallString, this.#editor);

        this.#callNodeDraftState = null;

        if (blurEditor) {
            this.setEditorFocus(false);
        }
    }

    constructor(
        @inject('FormulaService') private readonly formulaService: ReactBindings['FormulaService'],
        @inject('CodeDocumentModel') private readonly document: ReactBindings['CodeDocumentModel'],
    ) {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);
    }

    @reacts set decorations(decorations: Map<CustomText, SlateTextDecoration>) {
        this.#decorations = decorations;
    }

    get decorations() {
        return this.#decorations;
    }

    get editorDecoration() {
        if (this.#isFocused) {
            const inputHelperValues = this.#getInputHelperValues();
            if (inputHelperValues) {
                const params: string[] =
                    this.#callNodeDraftState?.draftParams ??
                    inputHelperValues[1].arguments.map((a) => {
                        return renderArgumentToString(a) || '';
                    });

                const stack = [];
                for (const node of iterateAstUpward(inputHelperValues[1])) {
                    if (node.type === 'call') {
                        stack.unshift(node.source);
                    }
                }

                const showIndicatorNextPreviousButtons =
                    // Don't do any more work if we already know there's multiple indicators
                    Boolean(stack.length) ||
                    // Check if there's any call node that's not the current one
                    iterateAst(this.#editorResult.result?.root).some(
                        (node) => node.type === 'call' && node !== inputHelperValues[1],
                    );

                return {
                    type: 'input_helper' as const,
                    callNode: inputHelperValues[1],
                    inputHelperIndicator: inputHelperValues[0],
                    params,
                    showIndicatorNextPreviousButtons,
                    stack,
                } satisfies EditorDecorationInputHelper;
            }

            const showPicker = (this.document.code?.length ?? 0) === 0;
            if (showPicker) {
                return { type: 'picker' as const, selectedIndex: 0 };
            }

            const node = this.#knownAstNodeUnderSelection;
            if (node?.type === 'call') {
                return { type: 'completion' as const, selectedIndex: 0, query: node.source };
            }
        }

        if (this.document.code.length && this.document.errors.length) {
            return { type: 'errors' as const };
        }

        return { type: 'none' as const };
    }

    @reacts private set editorResult(editorResult: CodeEditorResult) {
        this.#editorResult = editorResult;
        this.resultChangeTarget.dispatchEvent(
            new CustomEvent(CODE_EDITOR_RESULT_CHANGE_EVENT, {
                detail: editorResult,
            }) satisfies CodeEditorResultChangeEvent,
        );
    }

    /**
     * Multiple values are required to show the input helper. We centralize logic here so that required values are
     * all-or-nothing.
     */
    #getInputHelperValues(): [IndicatorImportModel, CallNode] | null {
        if (this.#callNodeDraftState) {
            return [this.#callNodeDraftState.indicatorImport, this.#callNodeDraftState.originalCallNode];
        }

        if (!this.#knownAstNodeUnderSelection) return null;

        if (this.#knownAstNodeUnderSelection.type !== 'call') return null;

        const ref = this.#knownAstNodeUnderSelection.source;
        const importModel = this.importsManager.imports.find((imported) => imported.alias === ref);
        if (!importModel) return null;
        return [importModel, this.#knownAstNodeUnderSelection];
    }

    // TODO: Move to Transforms helper
    #moveToEditorCharacterOffset(offset: number) {
        let currentOffset = 0;
        for (const [node, path] of Editor.nodes(this.#editor, {
            at: [0],
            match: (n) => Text.cheapIsText(n),
        }) as Iterable<[CustomText, Path]>) {
            currentOffset += node.text.length;
            if (offset <= currentOffset) {
                const point = { path, offset: node.text.length };
                Transforms.select(this.#editor, point);
                return;
            }
        }
    }

    async goToNextIndicator() {
        if (this.#callNodeDraftState) {
            this.commitIndicatorNodeDraft(false);
        }

        if (!this.#editorResult.result?.root) {
            return;
        }

        const currentCallNode = this.#getInputHelperValues()?.[1];
        if (!currentCallNode) {
            return;
        }

        let firstCallNode: CallNode | undefined = undefined;
        let useNextCallNode = false;
        for (const node of iterateAst(this.#editorResult.result.root)) {
            if (!firstCallNode && node.type === 'call') {
                firstCallNode = node;
            }

            if (node === currentCallNode) {
                useNextCallNode = true;
                continue;
            }

            if (useNextCallNode && node.type === 'call') {
                const offset = node.ranges.source.end;
                this.#moveToEditorCharacterOffset(offset);
                this.selection = this.#editor.selection;
                return;
            }
        }

        if (firstCallNode) {
            const offset = firstCallNode.ranges.source.end;
            // Move editor selection to the end of the next call node
            this.#moveToEditorCharacterOffset(offset);
            this.selection = this.#editor.selection;
            return;
        }
    }

    goToPreviousIndicator() {
        if (this.#callNodeDraftState) {
            this.commitIndicatorNodeDraft(false);
        }

        if (!this.#editorResult.result?.root) {
            return;
        }

        const currentCallNode = this.#getInputHelperValues()?.[1];
        if (!currentCallNode) {
            return;
        }

        let previousCallNode: CallNode | undefined = undefined;
        for (const node of iterateAst(this.#editorResult.result.root)) {
            if (!previousCallNode && node.type === 'call') {
                previousCallNode = node;
                continue;
            }

            if (node === currentCallNode) {
                if (!previousCallNode) {
                    return;
                }
                const offset = previousCallNode.ranges.source.end;
                this.#moveToEditorCharacterOffset(offset);
                this.selection = this.#editor.selection;
                return;
            }

            if (node.type === 'call') {
                previousCallNode = node;
            }
        }

        if (previousCallNode) {
            const offset = previousCallNode.ranges.source.end;
            this.#moveToEditorCharacterOffset(offset);
            this.selection = this.#editor.selection;
        }
    }

    init(editor: ReactEditor, importsManager: ReactBindings['ImportsManagerModel'], initialCode: string = '') {
        this.#editor = editor;
        this.importsManager = importsManager;

        void this.document.updateCode(initialCode);

        this.#autoUpdateImports = debounce((node) => {
            this.importsManager.autoImportFromCode(node);
        }, 500);

        const subscription = from(this.importsManager).subscribe(() => {
            this.update(this.document.code);
        });
        this.disposableStack.defer(() => subscription.unsubscribe());

        const originalOnChange = editor.onChange;
        editor.onChange = (options) => {
            originalOnChange(options);
            if (editor.selection !== this.#selection) {
                this.selection = editor.selection;
            }
        };
    }

    get isFocused() {
        return this.#isFocused;
    }

    @reacts private set isFocused(isFocused: boolean) {
        const previousIsFocused = ReactEditor.isFocused(this.#editor);
        this.#isFocused = isFocused;

        if (this.#callNodeDraftState) {
            this.commitIndicatorNodeDraft(!isFocused);
        }

        if (!previousIsFocused && isFocused) {
            ReactEditor.focus(this.#editor, { retries: 5 });

            // On initial focus, the editor may have no selection, so we set the initial selection to the end of the editor
            if (!this.#editor.selection) {
                const end = Editor.end(this.#editor, [0]);
                Transforms.select(this.#editor, { anchor: end, focus: end });
            }
        }
    }

    get result() {
        return this.#editorResult;
    }

    resultChangeTarget = new EventTarget();

    @reacts private set selection(selection: Range | null) {
        this.#selection = selection;

        // If selection is changing due to a code change, don't recalibrate the known AST node
        if (this.#codeUpdateAbortController) {
            return;
        }

        this.#updateKnownAstNodeUnderSelection();
    }

    setEditorFocus(isFocused: boolean) {
        this.isFocused = isFocused;
    }

    async update(code: string): Promise<void> {
        if (this.#codeUpdateAbortController) {
            this.#codeUpdateAbortController.abort();
        }

        const thisUpdateAbort = new AbortController();
        this.#codeUpdateAbortController = thisUpdateAbort;

        if (this.#updateParseOptionsPromise) {
            await this.#updateParseOptionsPromise;
            this.#updateParseOptionsPromise = null;
            if (thisUpdateAbort.signal.aborted) return;
        }

        await this.document.updateCode(code);

        if (thisUpdateAbort.signal.aborted) return;

        mapEditorElementsToAstNodes(this.#editor, this.document.parserResult);

        this.#updateErrorDecorations();
        this.#updateSyntaxHighlightMarks();
        this.#updateKnownAstNodeUnderSelection();

        if (this.document.parserResult?.root) {
            this.#autoUpdateImports(this.document.parserResult.root);
        }

        this.editorResult = {
            code,
            result: this.document.parserResult,
            isValid: this.document.isValid,
            errors: this.document.errors,
        };

        this.#codeUpdateAbortController = null;
    }

    updateCurrentIndicatorStackIndex(_index: number) {
        // TODO
    }

    #updateErrorDecorations() {
        const errors = this.document.errors;

        // Special cases to do less work when there are no errors
        if (errors.length === 0) {
            // Don't create a new reference when there's no change
            if (this.#decorations.size === 0) {
                return;
            }

            // Clear decorations when there are no errors
            this.decorations = new Map();
        }

        const decorations = new Map();

        let textOffset = 0;
        for (const [node, path] of Editor.nodes(this.#editor, {
            at: [0],
            match: (n) => Text.cheapIsText(n),
        }) as Iterable<[CustomText, Path]>) {
            const hasError = errors.some(
                (err) => Math.max(textOffset, err.start) <= Math.min(textOffset + node.text.length, err.end),
            );

            if (hasError) {
                decorations.set(node, {
                    anchor: { path, offset: 0 },
                    focus: { path, offset: node.text.length },
                    decoration: { hasError },
                });
            }

            textOffset += node.text.length;
        }

        this.decorations = decorations;
    }

    updateIndicatorNodeDraft(params: string[]) {
        if (!this.#callNodeDraftState) {
            const inputHelperValues = this.#getInputHelperValues();
            if (!inputHelperValues) {
                return;
            }

            // Note that inputHelperValues at this time will give `originalCallNode` as the current call node
            // After the first call to this method, the `originalCallNode` will be the original call node
            const [indicatorImport, originalCallNode] = inputHelperValues;

            this.#callNodeDraftState = createCallNodeDraftState(
                indicatorImport,
                originalCallNode,
                this.#editor.children as CustomElement[],
            );
        }

        this.#callNodeDraftState = updateCallNodeDraftStateParams(this.#callNodeDraftState, params);

        this.#editor.children = this.#callNodeDraftState.slateEditorChildrenSnapshot;
        updateIndicatorNode(
            this.#callNodeDraftState.originalCallNode,
            this.#callNodeDraftState.replacementCallString,
            this.#editor,
        );
    }

    #updateKnownAstNodeUnderSelection() {
        // TODO: Can we just use the selection from the editor?
        const selection = this.#selection;
        if (!selection) return null;

        // TODO: Express this as a single `Editor.node` call which gets the correct node from the match
        for (const [el] of Editor.nodes(this.#editor, {
            at: selection,
            match: (n, p) => {
                if (p.length !== 2) {
                    return false;
                }
                if (Text.cheapIsText(n) && n.text === '') {
                    return false;
                }
                return true;
            },
        })) {
            const astNode = SLATE_ELEMENT_TO_AST_NODE.get(el as any);
            if (astNode) {
                this.#knownAstNodeUnderSelection = astNode;
                return;
            }
        }

        this.#knownAstNodeUnderSelection = null;
    }

    updateParseOptions(options: EditorServiceParseOptions) {
        this.#updateParseOptionsPromise = this.document.updateParseOptions(options);
        this.update(this.document.code);
    }

    #updateSyntaxHighlightMarks() {
        const result = this.document.parserResult;
        if (!result) return;

        Editor.withoutNormalizing(this.#editor, () => {
            for (const [node, path] of Editor.iterateFilterBlockChildren(this.#editor)) {
                const astNode = SLATE_ELEMENT_TO_AST_NODE.get(node);

                const [textElement, textElementPath]: [CustomText, Path] = Text.cheapIsText(node)
                    ? [node, path]
                    : [(node as { children: CustomText[] }).children[0], [...path, 0]];

                if (!astNode) {
                    if (textElement.syntaxClassname) {
                        Transforms.setNodes(this.#editor, { syntaxClassname: undefined }, { at: textElementPath });
                    }
                    continue;
                }

                let syntaxClassname;
                if (astNode) {
                    if (astNode.type === 'call') {
                        if (textElement.text === '(' || textElement.text === ')' || textElement.text === ',') {
                            syntaxClassname = SYNTAX_CLASSNAMES.indicatorToken;
                        } else {
                            syntaxClassname = SYNTAX_CLASSNAMES.indicator;
                        }
                    } else if (astNode.type === 'const') {
                        if (astNode.dataType === 'string') {
                            syntaxClassname = SYNTAX_CLASSNAMES.string;
                        } else if (astNode.unit === 'quantity') {
                            syntaxClassname = SYNTAX_CLASSNAMES.quantity;
                        } else if (astNode.dataType === 'timeframe' || astNode.dataType === 'pointInTime') {
                            syntaxClassname = SYNTAX_CLASSNAMES.pointInTimeOrTimeframe;
                        } else if (astNode.dataType === 'periodicity') {
                            syntaxClassname = SYNTAX_CLASSNAMES.duration;
                        }
                    }
                }

                if (textElement.syntaxClassname !== syntaxClassname) {
                    Transforms.setNodes(this.#editor, { syntaxClassname }, { at: textElementPath });
                    continue;
                }
            }
        });
    }
}
