import type { RawClient } from '@thinkalpha/table-client-legacy';
import { ProxyClient } from '@thinkalpha/table-client-legacy';
// import { filter } from 'rxjs/operators';
import { appConfig } from 'src/config';
import { container } from 'src/ioc/StaticContainer';

const riskClient = new ProxyClient(appConfig.riskTableProxy);

const log = container.get('Logger').getSubLogger({ name: 'table-service' });

// riskClient.authStatus$.pipe(filter((x) => x === AuthStatus.badToken)).subscribe(() => {
//     log.warn({ message: 'table-ws has rejected the user token' });
// });

const exportedClient: RawClient = riskClient;

export const usePureRiskProxyClient = () => {
    return riskClient;
};

export { exportedClient as riskClient };

export const setAccessToken = (accessToken: string) => {
    log.info({ message: 'About to log in to risk server using token', accessToken });
    riskClient.token = accessToken;
};
