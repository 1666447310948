import { appConfig } from '../../config';
import type { LocatesClient } from './interface';
import type { AxiosInstance } from 'axios';
import { ReplaySubject, firstValueFrom } from 'rxjs';
import { createInstance } from 'src/api';
import type {
    LocateInventoryPoolUploadRequest,
    LocateInventoryPoolUploadResponse,
    SecurityInventory,
    UploadableLocateProvider,
    UploadableLocateProviderResponse,
} from 'src/contracts/locates/inventory';
import type {
    LocateAcceptResponse,
    LocateCancelResponse,
    LocateLimitRequest,
    LocateLimitResponse,
    LocateOrdersForUserResponse,
    LocateRejectResponse,
    NewLocateOrderRequest,
    NewLocateOrderResponse,
    LocateOrder,
    NewLocateOrder,
    AutoAcceptInfo,
    ClearLocateOrdersResponse,
    ClearLocateOrdersRequest,
} from 'src/contracts/locates/orders';
import type {
    LocateAccount,
    LocateInjectRequest,
    LocateInjectResponse,
    LocatePlatformDiscoveryRequest,
    LocatePlatformDiscoveryResponse,
    LocateReceipt,
} from 'src/contracts/locates/platforms';
import type { LocateProvider, LocateProviderResponse } from 'src/contracts/locates/providers';

const tokenSubj = new ReplaySubject<AxiosInstance>(1);

export const setAccessToken = (accessToken: string) => {
    tokenSubj.next(
        createInstance({
            baseURL: appConfig.locatesApi,
            headers: { Authorization: `Bearer ${accessToken}` },
        }),
    );
};

const api$ = tokenSubj.asObservable();

export class LocatesClientImpl implements LocatesClient {
    constructor() {
        /*empty*/
    }

    async getLocatesProviders(): Promise<LocateProvider[]> {
        // TODO: handle any expected errors and ensure destructuring is safe
        const api = await firstValueFrom(api$);
        const { data } = await api.get<LocateProviderResponse>('/providers');
        return data.providers;
    }

    async getUploadableLocatesProviders(): Promise<UploadableLocateProvider[]> {
        const api = await firstValueFrom(api$);
        const { data } = await api.get<UploadableLocateProviderResponse>('/inventory');
        return data.providers;
    }

    async getLocatesOrders(): Promise<LocateOrder[]> {
        // TODO: handle any expected errors and ensure destructuring is safe
        const api = await firstValueFrom(api$);
        const { data } = await api.get<LocateOrdersForUserResponse>('/orders');
        return data.orders;
    }

    async getLocatesAccounts(accountIds?: string[]): Promise<LocateAccount[]> {
        // TODO: handle any expected errors and ensure destructuring is safe
        const api = await firstValueFrom(api$);
        const { data } = await api.post<LocatePlatformDiscoveryResponse>('/platforms/discover', {
            accountIds: accountIds,
        } satisfies LocatePlatformDiscoveryRequest);
        return data.accounts as LocateAccount[]; // TODOLOCATES: locates contracts use latest plat-common with deny permission support
    }

    async createLocatesOrder(order: NewLocateOrder, autoAcceptPrice?: number): Promise<LocateOrder[]> {
        // TODO: handle any expected errors and ensure destructuring is safe
        const api = await firstValueFrom(api$);
        const { data } = await api.post<NewLocateOrderResponse>('/orders', {
            order,
            autoAccept:
                typeof autoAcceptPrice === 'number'
                    ? {
                          enabled: true,
                          price: autoAcceptPrice,
                      }
                    : undefined,
        } satisfies NewLocateOrderRequest);
        return data.orders;
    }

    async injectLocate(receipts: LocateReceipt[], accountId: string): Promise<LocateOrder[]> {
        const api = await firstValueFrom(api$);
        const { data } = await api.post<LocateInjectResponse>(`/platforms/accounts/${accountId}/inject`, {
            receipts,
        } satisfies LocateInjectRequest);
        return data.injections;
    }

    // TODOLOCATES PLAT-5357 - maybe change the response type to something more useful
    async uploadLocate(poolId: string, inventory: SecurityInventory[]): Promise<LocateInventoryPoolUploadResponse> {
        const api = await firstValueFrom(api$);
        const { data } = await api.post<LocateInventoryPoolUploadResponse>(`/inventory/${poolId}/upload`, {
            inventory,
        } satisfies LocateInventoryPoolUploadRequest);
        return data;
    }

    async acceptLocatesOrder(orderId: string): Promise<LocateOrder> {
        // TODO: handle any expected errors and ensure destructuring is safe
        const api = await firstValueFrom(api$);
        const { data } = await api.post<LocateAcceptResponse>(`/orders/${orderId}/accept`);
        return data.order;
    }

    async rejectLocatesOrder(orderId: string): Promise<LocateOrder> {
        // TODO: handle any expected errors and ensure destructuring is safe
        const api = await firstValueFrom(api$);
        const { data } = await api.patch<LocateRejectResponse>(`/orders/${orderId}/reject`);
        return data.order;
    }

    async rejectAllLocatesOrders(accountIds: string[]): Promise<LocateOrder[]> {
        // TODO: handle any expected errors and ensure destructuring is safe
        const api = await firstValueFrom(api$);
        const { data } = await api.patch<ClearLocateOrdersResponse>(`/orders/clear`, {
            accountIds,
        } satisfies ClearLocateOrdersRequest);
        return data.orders;
    }

    async cancelLocatesOrder(orderId: string): Promise<LocateOrder> {
        // TODO: handle any expected errors and ensure destructuring is safe
        const api = await firstValueFrom(api$);
        const { data } = await api.patch<LocateCancelResponse>(`/orders/${orderId}/cancel`);
        return data.order;
    }

    async changeAutoAccept(orderId: string, autoAccept: AutoAcceptInfo): Promise<LocateOrder> {
        const api = await firstValueFrom(api$);
        const { data } = await api.put<LocateLimitResponse>(`/orders/${orderId}/limit`, {
            autoAccept,
        } satisfies LocateLimitRequest);
        return data.order;
    }
}
