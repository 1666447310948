import type {
    AdditionalInfo,
    AccountType,
    PersonalInfo,
    ContactInfo,
    TaxInfo,
    Identification,
    Affiliations,
    InvestmentProfile,
    TrustedContact,
    EDelivery,
    TermsAndAgreements,
} from 'src/features/account-setup/schemas';
import type { AppAction } from 'src/store/actions';

export interface FormState {
    additionalInfo?: AdditionalInfo;
    accountType?: AccountType;
    personalInfo?: PersonalInfo;
    contactInfo?: ContactInfo;
    taxInfo?: TaxInfo;
    identification?: Identification;
    affiliations?: Affiliations;
    investmentProfile?: InvestmentProfile;
    trustedContact?: TrustedContact;
    eDelivery?: EDelivery;
    termsAndAgreements?: TermsAndAgreements;
}

type FormStateData =
    | { key: 'additionalInfo'; data: AdditionalInfo }
    | { key: 'accountType'; data: AccountType }
    | { key: 'personalInfo'; data: PersonalInfo }
    | { key: 'contactInfo'; data: ContactInfo }
    | { key: 'taxInfo'; data: TaxInfo }
    | { key: 'identification'; data: Identification }
    | { key: 'affiliations'; data: Affiliations }
    | { key: 'investmentProfile'; data: InvestmentProfile }
    | { key: 'trustedContact'; data: TrustedContact }
    | { key: 'eDelivery'; data: EDelivery }
    | { key: 'termsAndAgreements'; data: TermsAndAgreements };

export interface UpdateFormStateAction {
    type: 'accountSetup::form::save';
    payload: FormStateData;
}

export const updateFormState = (payload: FormStateData): UpdateFormStateAction => ({
    type: 'accountSetup::form::save',
    payload,
});

export type AccountFormActions = ReturnType<typeof updateFormState>;

const initialState: FormState = {};

export const accountSetupFormReducer = (state = initialState, action: AppAction): FormState => {
    switch (action.type) {
        case 'accountSetup::form::save': {
            switch (action.payload.key) {
                case 'additionalInfo':
                    state.additionalInfo = action.payload.data;
                    break;
                case 'accountType':
                    state.accountType = action.payload.data;
                    break;
                case 'personalInfo':
                    state.personalInfo = action.payload.data;
                    break;
                case 'contactInfo':
                    state.contactInfo = action.payload.data;
                    break;
                case 'taxInfo':
                    state.taxInfo = action.payload.data;
                    break;
                case 'identification':
                    state.identification = action.payload.data;
                    break;
                case 'affiliations':
                    state.affiliations = action.payload.data;
                    break;
                case 'investmentProfile':
                    state.investmentProfile = action.payload.data;
                    break;
                case 'trustedContact':
                    state.trustedContact = action.payload.data;
                    break;
                case 'eDelivery':
                    state.eDelivery = action.payload.data;
                    break;
                case 'termsAndAgreements':
                    state.termsAndAgreements = action.payload.data;
                    break;
            }
        }

        default:
            return state;
    }
};
