import { NaturalLanguageAtomState, type NaturalLanguageDisplayMode } from '../../types/naturalLanguageAtomState';
import {
    NLP_STATE_CHANGE_EVENT,
    type NLPEditorService,
    type NLPEditorServiceInitOptions,
    type NLPStateChangeEvent,
} from './interface';
import {
    handleAcceptSuggestion,
    handleCodeChange,
    handleConfirmParsedResponse,
    handleEditParsedResponse,
    handleNaturalLanguageChange,
    handleParsedResponse,
    handleParsedResponseError,
    handleParsing,
    handleRejectParsedResponse,
    handleSetMode,
} from './naturalLanguageAtomState';
import type { CodeEditorResult } from 'src/features/code-editor';
import { inject, ReactiveInjectable, reacts } from 'src/features/ioc';
import type { ReactBindings } from 'src/ioc/types';
import type { ImportsManagerModelType } from 'src/ioc/types/ImportsManagerModel';
import { parseNaturalLanguageP } from 'src/lib/nlp';
import { NlpQueryType, type NlpSuggestion } from 'src/lib/nlp/contracts';
import { v4 } from 'uuid';

export class NLPEditorServiceImpl extends ReactiveInjectable implements NLPEditorService {
    #importsManager!: ImportsManagerModelType;

    #nlpState!: NaturalLanguageAtomState;

    #id = v4();

    #isSubmitting = false;

    #queryType: NlpQueryType = NlpQueryType.condition;

    acceptSuggestion(suggestion: NlpSuggestion): void {
        this.nlpState = handleAcceptSuggestion(this.nlpState, suggestion);
    }

    codeChange(result: CodeEditorResult): void {
        this.nlpState = handleCodeChange(this.nlpState, result);
    }

    async confirmParsedResponse(): Promise<void> {
        const parsedStateAtom = this.#nlpState.atom;
        if (parsedStateAtom.status !== 'parsed') {
            return;
        }

        const fixedFormula = await this.#importsManager.addImportsFromRefsAndFormula(
            parsedStateAtom.unconfirmedFormula.aliases,
            parsedStateAtom.unconfirmedFormula.formula,
        );
        this.nlpState = handleConfirmParsedResponse(this.#nlpState, fixedFormula);
    }

    constructor(@inject('QueryClient') private queryClient: ReactBindings['QueryClient']) {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);
    }

    editParsedResponse(): void {
        this.nlpState = handleEditParsedResponse(this.#nlpState);
    }

    // TODO: Add dataTypeRequired -> NlpQueryType.condition as option
    init({ initialState, importsManager }: NLPEditorServiceInitOptions): void {
        this.#nlpState = initialState;
        this.#importsManager = importsManager;
    }

    get isSubmitting(): boolean {
        return this.#isSubmitting;
    }

    @reacts set isSubmitting(isSubmitting: boolean) {
        this.#isSubmitting = isSubmitting;
    }

    get nlpState(): NaturalLanguageAtomState {
        return this.#nlpState;
    }

    @reacts private set nlpState(nlpState: NaturalLanguageAtomState) {
        this.#nlpState = nlpState;
        this.stateChangeTarget.dispatchEvent(
            new CustomEvent(NLP_STATE_CHANGE_EVENT, { detail: nlpState }) satisfies NLPStateChangeEvent,
        );
    }

    naturalLanguageChange(naturalLanguage: string): void {
        this.nlpState = handleNaturalLanguageChange(this.#nlpState, naturalLanguage);
    }

    rejectParsedResponse(): void {
        this.nlpState = handleRejectParsedResponse(this.#nlpState);
    }

    setMode(mode: NaturalLanguageDisplayMode): void {
        this.nlpState = handleSetMode(this.#nlpState, mode);
    }

    setQueryType(queryType: NlpQueryType): void {
        this.#queryType = queryType;
    }

    stateChangeTarget: EventTarget = new EventTarget();

    async submit(): Promise<void> {
        try {
            this.isSubmitting = true;
            this.nlpState = handleParsing(this.#nlpState);
            const response = await this.queryClient.fetchUserQuery({
                queryKey: ['nlp', this.#nlpState.naturalLanguage],
                queryFn: () => parseNaturalLanguageP(this.#nlpState.naturalLanguage, this.#queryType, this.#id),
            });

            if ('result' in response) {
                this.nlpState = handleParsedResponse(this.#nlpState, response);
            } else {
                this.nlpState = handleParsedResponseError(this.#nlpState, response);
            }
        } finally {
            this.isSubmitting = false;
        }
    }
}
