import type { AppAction } from '../actions';
import type { WorkspaceViewModel } from '../types';

export interface WorkspaceState {
    currentWorkspace: WorkspaceViewModel | null;
    currentWorkspaceStatus: 'initial' | 'pending' | 'saving' | 'error' | 'success';
}

export const initialState: WorkspaceState = {
    currentWorkspace: null,
    currentWorkspaceStatus: 'initial',
};

export const workspace = (state = initialState, action: AppAction): WorkspaceState => {
    switch (action.type) {
        case 'setResearchingWorkspace': {
            return {
                ...state,
                currentWorkspaceStatus: 'pending',
            };
        }

        case 'completeWorkspaceResearch': {
            return {
                ...state,
                currentWorkspaceStatus: 'success',
            };
        }

        case 'refresh-token::clear': {
            return { ...initialState };
        }

        case 'initializeWorkspace': {
            return {
                ...state,
                currentWorkspace: action.workspace,
            };
        }

        case 'addContainer': {
            const currentWorkspace = state.currentWorkspace!;

            return {
                ...state,
                currentWorkspace: {
                    ...currentWorkspace,
                    isDirty: true,
                    containerIds: [...(currentWorkspace.containerIds ?? []), action.container.id],
                },
            };
        }

        case 'removeContainer': {
            const currentWorkspace = state.currentWorkspace!;

            return {
                ...state,
                currentWorkspace: {
                    ...currentWorkspace,
                    isDirty: true,
                    containerIds: (currentWorkspace.containerIds ?? []).filter((x) => x !== action.container.id),
                },
            };
        }

        /** just set the workspace to dirty */
        case 'updateWidget':
        case 'moveTab':
        case 'removeTagFromWatchList':
        case 'addTagToWatchList':
        case 'updateActiveTab':
        case 'removeTab':
        case 'addTab':
        case 'updateTabName':
        case 'setSelectedUniverse':
        case 'userSetNewsStrategy':
        case 'userSetNewsUniverse':
        case 'onTabChannelChange':
        case 'updateWorkspaceLayout': {
            if ('skipDirtyCheck' in action && action.skipDirtyCheck === true) return state;
            const currentWorkspace = state.currentWorkspace!;

            if (currentWorkspace.isDirty) return state;

            return {
                ...state,
                currentWorkspace: {
                    ...currentWorkspace,
                    isDirty: true,
                },
            };
        }

        case 'setSavingTrueAction': {
            return {
                ...state,
                currentWorkspaceStatus: 'saving',
            };
        }

        case 'completedSavingWorkspace': {
            return {
                ...state,
                currentWorkspaceStatus: 'success',
            };
        }

        case 'workspaceSavingFailed': {
            return {
                ...state,
                currentWorkspaceStatus: 'error',
            };
        }

        case 'updateWorkspaceSettings': {
            const currentWorkspace = state.currentWorkspace;

            if (!currentWorkspace) return state;

            const currentlyIsPrimary = currentWorkspace.isDefaultWorkspace;

            const { name, isPrimaryWorkspace, isLocked, snappingGridEnabled, activeBorderColor } = action.settings;

            const anyChanges =
                currentWorkspace.name !== name ||
                currentlyIsPrimary !== isPrimaryWorkspace ||
                currentWorkspace.layoutSettings?.containerLayoutLocked !== isLocked ||
                currentWorkspace.layoutSettings?.gridSnappingEnabled !== snappingGridEnabled ||
                currentWorkspace.activeBorderColor !== activeBorderColor;

            if (!anyChanges) {
                return state;
            }

            return {
                ...state,
                currentWorkspace: {
                    ...currentWorkspace,
                    name: action.settings.name,
                    isDirty: false,
                    layoutSettings: {
                        ...currentWorkspace.layoutSettings,
                        containerLayoutLocked: isLocked,
                        gridSnappingEnabled: snappingGridEnabled,
                    },
                    activeBorderColor: action.settings.activeBorderColor,
                },
            };
        }
        case 'setMaximizedContainer': {
            const currentWorkspace = state.currentWorkspace!;
            return {
                ...state,
                currentWorkspace: {
                    ...currentWorkspace,
                    maximizedContainerId: action.containerId,
                },
            };
        }
        default:
            return state;
    }
};
